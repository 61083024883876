import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const TrainingPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>تدريبات FishmedNet</h2>
    <p>
      في إطار الأنشطة التي يقوم بها مشروع FISHMEDNET، التدريب يمثل أداة رئيسية لتحسين مهارات الشركات وتطوير فرص جديدة لتنويع أنشطة الصيد.
    </p>
    <p>
      من خلال 4 وحدات تدريبية، مخصصة لمواضيع محددة، يقدم الخبراء المشاركين تحليلًا للتحديات والفرص في تنويع الأنشطة، مع تقديم شهادات عن الممارسات الجيدة الموجودة بالفعل في سياق البحر الأبيض المتوسط.
    </p>
    <p>
      <a href="https://www.youtube.com/@fishmednetenicbcmed/playlists">https://www.youtube.com/@fishmednetenicbcmed/playlists</a>
    </p>

    <h3>السياحة الساحلية والبحرية المستدامة</h3>
    <br/>
    <strong>من السياحة البيئية إلى سياحة الصيد، كل الخطوات اللازمة لبدء نشاط جديد</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Cioiob3KR6E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h3>تربية الأحياء المائية</h3>
    <br/>
    <strong>ما هي الفرص والتحديات في هذا القطاع وكيفية بدء وإدارة نشاط الأحياء المائية؟</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/y4jJAiLegH0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h3>صناعة المواد الغذائية</h3>
    <br/>
    <strong>مقدمة لعمليات التحويل الرئيسية للمنتجات البحرية والمخاطر المتعلقة بها</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/UnhuBUPNL3A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h3>خدمات مجتمعية</h3>
    <br/>
    <strong>نظرة عامة على مختلف الخدمات البيئية المرتبطة بتنويع الصيد</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/G6xESiltqtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h2>للإطلاع على آخر مستجداتنا</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default TrainingPage
